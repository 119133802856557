<template>
  <div>
    <label>
      <input
        type="radio"
        class="fancy-radio"
        name="faceOf"
        v-model="value"
        :value="slugify(category.title)"
        :disabled="category.taken"
      >
      <div :class="faceClass" @click="$emit('new-value', slugify(category.title))">
        <div class="has-background-danger is-corner-banner" v-show="category.taken">
          GONE
        </div>
        <div class="has-background-success is-corner-banner" v-show="category.selected">
          RESERVED
        </div>
        <div class="has-text-centered">
          <span class="has-text-weight-bold is-size-7">
            THE FACE OF
          </span>
          <br>
          <span :class="faceTextClass">{{ category.title }}</span>
        </div>
      </div>
    </label>
  </div>
</template>

<script>
import slug from '@/lib/slug';

export default {
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      value: null,
    };
  },
  computed: {
    faceClass() {
      return {
        'face-category': true,
        'is-selected': this.category.selected,
        'is-taken': this.category.taken,
      };
    },
    faceTextClass() {
      return {
        'is-size-5': true,
        'has-text-weight-light': true,
        'has-text-grey-light': this.category.taken,
      };
    },
  },
  methods: {
    slugify(string) {
      return slug(string);
    },
  },
};
</script>
